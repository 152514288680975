import React from "react";
import Slider from "@material-ui/core/Slider";
import { makeStyles } from "@material-ui/core/styles";
import { getThemeById } from "./../../theme";

const useStyles = makeStyles((theme) => ({
  customSlider: {
    "& .MuiSlider-root": {
      color: theme.palette.menuGray,
      height: 8,
    },
    "& .MuiSlider-thumb": {
      height: 24,
      width: 24,
      backgroundColor: theme.palette.white,
      border: theme.borders[3],
      marginTop: -8,
      marginLeft: -12,
      "&:focus, &:hover, &$active": {
        boxShadow: "inherit",
      },
    },
    "& .MuiSlider-valueLabel": {
      left: "calc(-50% + 4px)",
    },
    "& .MuiSlider-track": {
      height: 8,
      borderRadius: 4,
      color: theme.palette.primary.main,
    },
    "& .MuiSlider-rail": {
      height: 8,
      color: theme.palette.gray,
    },
  },
}));

const CustomSlider = ({ asset, ...rest }) => {
  const theme = getThemeById(asset?.id);
  const classes = useStyles(theme);

  return <Slider className={classes.customSlider} {...rest} />;
};

export default CustomSlider;
