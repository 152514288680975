import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import CheckIcon from "@material-ui/icons/Check";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import theme from "../../theme";

const useStyles = makeStyles((th) => ({
  box: {
    display: "flex",
    position: "relative",
    border: th.borders[6],
    borderRadius: "5px",
    height: "22px",
    width: "22px",
  },
  checkedBackground: {
    backgroundColor: th.palette.primary.main,
  },
}));

function CheckBoxCustom() {
  const classes = useStyles(theme);
  return <Box className={classes.box} />;
}

function CheckedBox() {
  const classes = useStyles(theme);
  return (
    <Box className={`${classes.box} ${classes.checkedBackground}`}>
      <CheckIcon style={{ color: theme.palette.white }} />
    </Box>
  );
}

function CustomCheckBox2({ checked = false, disabled = false, onChange, ...rest }) {
  return (
    <Checkbox
      checked={checked}
      onChange={onChange}
      disabled={disabled}
      icon={<CheckBoxCustom />}
      checkedIcon={<CheckedBox />}
      {...rest}
    />
  );
}

export default CustomCheckBox2;
