import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { addSpacesFilterThunk } from "../../redux/actions/space-list-actions";

import FilterPricePopover from "../../components/spaces-list/filter-price-popover";

function SpaceListFilterPricePopover({ values = [], onClose, ...rest }) {
  const dispatch = useDispatch();

  const [price, setPrice] = useState(values);

  const minPrice = useSelector((state) => state.spaceList.filters.priceMin);
  const maxPrice = useSelector((state) => state.spaceList.filters.priceMax);

  const addFilter = () => {
    dispatch(
      addSpacesFilterThunk({ priceStart: price[0], priceEnd: price[1] })
    );
  };

  useEffect(() => {
    setPrice(values);
  }, [values]);

  return (
    <FilterPricePopover
      values={price}
      onChangeValues={setPrice}
      onClose={onClose}
      addFilter={addFilter}
      minMaxValues={[minPrice, maxPrice]}
      {...rest}
    />
  );
}

export default SpaceListFilterPricePopover;
