import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import FiltersMobile from "../../components/spaces-list/filters-mobile";
import { getSpacesThunk } from "../../redux/actions/space-list-actions";
import {
  getSpaceTypesThunk,
  getCitiesAreasThunk,
  getSpaceFeaturesThunk,
  setCurrentAsset,
} from "../../redux/actions/misc-actions";

function SpaceListFilter(props) {
  const dispatch = useDispatch();

  const assets = useSelector((state) => state.misc.assets);
  const asset = useSelector((state) => state.spaceList.asset);
  //const asset = useSelector((state) => state.misc.currentAsset);

  const perPage = useSelector((state) => state.spaceList.perPage);

  const [assetName, setAssetName] = useState("");

  const onSearch = (id) => {
    const asset = assets.find((a) => a.id === parseInt(id));
    dispatch(getSpacesThunk(asset, perPage, 1));
    dispatch(setCurrentAsset(asset));
  };

  useEffect(() => {
    dispatch(getSpaceTypesThunk());
    dispatch(getSpaceFeaturesThunk());
    dispatch(getCitiesAreasThunk());
  }, []);

  useEffect(() => {
    setAssetName(asset ? asset.name : "");
  }, [asset]);

  return (
    <FiltersMobile
      assetName={assetName}
      asset={asset}
      assets={assets}
      onSearch={onSearch}
      {...props}
    />
  );
}

export default SpaceListFilter;
