import React from "react";
import Box from "@material-ui/core/Box";
import Image from "material-ui-image";
import { makeStyles } from "@material-ui/core/styles";
import theme from "../../theme";
import { Typography } from "@material-ui/core";
import numbro from "numbro";
import CloseIcon from "../../icons/close-icon";
import CircularProgress from "@material-ui/core/CircularProgress";
import { getSpaceSlug, limitStringChars } from "../../utils";
import Link from "../link";
import { Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { t } from "@lingui/macro";
import { getSpacePriceFromConfig } from "../../utils/spots";

const useStyles = makeStyles((theme) => ({
  mapCard: {
    display: "none",
    flexDirection: "row",
    position: "absolute",
    width: "80%",
    bottom: "-50%",
    left: "5%",
    height: "15%",
    borderRadius: "5px",
    backgroundColor: theme.palette.white,
  },
}));

function MapCard({ space, priceConfig, spot, status }) {
  const classes = useStyles({ ...theme });

  const price = getSpacePriceFromConfig(space, priceConfig);

  const { i18n } = useLingui();

  const priceConfigTranslations = {
    monthly: i18n._(t`Mes`),
    daily: i18n._(t`Día`),
  };

  return (
    <Box id="map-card" className={classes.mapCard}>
      <Link target={"_blank"} to={`/space/${getSpaceSlug(space)}?spot=${spot}`} style={{ display: "flex", width: "100%" }}>
        <Image
          disableSpinner
          color={theme.palette.darkGray}
          src={space.attachments?.length > 0 ? space.attachments[0].url : "/logo.svg"}
          style={{
            height: "100%",
            padding: "0",
            width: "30%",
            borderTopLeftRadius: "5px",
            borderBottomLeftRadius: "5px",
          }}
          imageStyle={{
            height: "100%",
            width: "100%",
            cursor: "pointer",
            objectFit: "cover",
          }}
        />
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          height="100%"
          width="60%"
          style={{ cursor: "pointer" }}
          p={1}
        >
          {status === "loading" && (
            <Box display="flex" alignItems="center" justifyContent="center" width="100%" height="100%">
              <CircularProgress />
            </Box>
          )}
          {status === "success" && (
            <>
              <Typography variant="subtitle1">{limitStringChars(space.title, 25)}</Typography>
              {price && (
                <Typography variant="subtitle1" color="primary">
                  <Trans>Desde</Trans>{" "}
                  {`${numbro(parseInt(price)).formatCurrency({ mantissa: 0 })}/${priceConfigTranslations[priceConfig]}`}
                </Typography>
              )}
            </>
          )}
        </Box>
      </Link>
      <Box
        display="flex"
        justifyContent="flex-end"
        p={1}
        style={{ cursor: "pointer" }}
        onClick={() => {
          const mapCard = document.getElementById("map-card");
          mapCard.style.bottom = "-50%";
          mapCard.style.display = "none";
        }}
      >
        <CloseIcon width="15px" height="15px" />
      </Box>
    </Box>
  );
}

export default MapCard;
