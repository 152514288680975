import React from "react";
import Box from "@material-ui/core/Box";
import numbro from "numbro";
import Button from "@material-ui/core/Button";
import CustomSlider from "../../containers/custom-slider";
import { Trans } from "@lingui/macro";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import CloseIcon from "../../icons/close-icon";

import theme from "../../theme";
import Popover from "@material-ui/core/Popover";

const useStyles = makeStyles((theme) => ({
  priceRange: {
    maxHeight: "100%",
    minWidth: "260px",
    marginTop: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  slider: {
    borderBottom: theme.borders[2],
  },
  closeIcon: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
}));

function SpaceListFilterPricePopover({
  values = [0, 100],
  minMaxValues = [0, 1000],
  onChangeValues = () => {},
  addFilter = () => {},
  onClose = () => {},
  ...rest
}) {
  const classes = useStyles(theme);

  return (
    <Popover onClose={onClose} {...rest}>
      <Box className={classes.priceRange}>
        <Box onClick={onClose} className={classes.closeIcon}>
          <Box style={{ cursor: "pointer" }}>
            <CloseIcon width="15px" height="15px" />
          </Box>
        </Box>
        <Box display="flex" flexDirection="column">
          <Typography variant="subtitle1">
            <Trans>Rango de precio</Trans>
          </Typography>
          <Box display="flex" justifyContent={"space-between"} flexDirection="row" mt={1} mb={1}>
            <Box>
              <Typography variant="subtitle1">{numbro(values[0]).formatCurrency()}</Typography>
            </Box>
            <Box ml={2}>
              <Typography variant="subtitle1">{numbro(values[1]).formatCurrency()}</Typography>
            </Box>
          </Box>
        </Box>
        <Box pl={1} pr={1} pb={1} className={classes.slider}>
          <CustomSlider
            value={values}
            min={minMaxValues[0]}
            max={minMaxValues[1]}
            onChange={(e, newValue) => {
              onChangeValues(newValue);
            }}
          />
        </Box>
        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" mt={2} mb={2}>
          <Typography
            variant="body1"
            onClick={() => {
              onChangeValues([minMaxValues[0], minMaxValues[1]]);
            }}
            style={{ textDecoration: "underline", cursor: "pointer" }}
          >
            <Trans>Borrar todo</Trans>
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              addFilter();
              onClose();
            }}
          >
            <Trans>Aplicar</Trans>
          </Button>
        </Box>
      </Box>
    </Popover>
  );
}

export default SpaceListFilterPricePopover;
