import React from "react";
import { useSelector } from "react-redux";
import MapCardComponent from "../../components/google-maps/map-card";

const MapCard = ({ priceConfig, spot }) => {
  const space = useSelector((state) => state.googleMaps.space);
  const status = useSelector((state) => state.googleMaps.spaceStatus);

  return <MapCardComponent space={space} spot={spot} priceConfig={priceConfig} status={status} />;
};

export default MapCard;
