import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { addSpacesFilterThunk } from "../../redux/actions/space-list-actions";

import FiltersDialog from "../../components/spaces-list/filters-dialog";

function SpaceFiltersDialog({ open, handleOnClose, ...rest }) {
  const dispatch = useDispatch();

  const minPrice = useSelector((state) => state.spaceList.filters.priceMin);
  const maxPrice = useSelector((state) => state.spaceList.filters.priceMax);

  const startPriceFilter = useSelector(
    (state) => state.spaceList.filters.priceStart
  );
  const endPriceFilter = useSelector(
    (state) => state.spaceList.filters.priceEnd
  );

  const minSize = useSelector((state) => state.spaceList.filters.sizeMin);
  const maxSize = useSelector((state) => state.spaceList.filters.sizeMax);

  const sizeStart = useSelector((state) => state.spaceList.filters.sizeStart);
  const sizeEnd = useSelector((state) => state.spaceList.filters.sizeEnd);

  const spaceTypes = useSelector((state) => state.misc.spaceTypes);
  const spaceFeatures = useSelector((state) => state.misc.spaceFeatures);
  const typesFilter = useSelector((state) => state.spaceList.filters.types);
  const usesFilter = useSelector((state) => state.spaceList.filters.uses);
  const asset = useSelector((state) => state.spaceList.asset);
  const featuresFilter = useSelector(
    (state) => state.spaceList.filters.features
  );

  const submit = (data) => {
    const newFilters = {
      types: [...data?.types],
      uses: [...data?.uses],
      features: [...data?.features],
      priceStart: data?.priceStart,
      priceEnd: data?.priceEnd,
      sizeStart: data?.sizeStart,
      sizeEnd: data?.sizeEnd,
    };
    dispatch(addSpacesFilterThunk(newFilters));
  };

  return (
    <FiltersDialog
      open={open}
      onClose={handleOnClose}
      spaceTypes={spaceTypes}
      spaceFeatures={spaceFeatures}
      typesFilter={typesFilter}
      usesFilter={usesFilter}
      featuresFilter={featuresFilter}
      price={[startPriceFilter, endPriceFilter]}
      minMaxPrice={[minPrice, maxPrice]}
      showPrices={asset.config?.show_space_price}
      size={[sizeStart, sizeEnd]}
      minMaxSize={[minSize, maxSize]}
      submit={submit}
      {...rest}
    />
  );
}

export default SpaceFiltersDialog;
