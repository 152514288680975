import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import theme from "../../theme";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { useLingui } from "@lingui/react";
import { t } from "@lingui/macro";
import CustomCheckbox from "../custom-checkbox";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    marginTop: theme.spacing(2),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    paddingBottom: theme.spacing(2),
    borderBottom: theme.borders[2],
  },
}));

const FilterCheckboxList = ({
  title = "",
  list = [],
  filter = [],
  onChange = () => {},
}) => {
  const classes = useStyles(theme);
  const { i18n } = useLingui();

  return (
    <Box className={classes.container}>
      <Typography variant="subtitle1">{title}</Typography>
      <Box mt={2} width="100%">
        <Grid container spacing={1}>
          {list.map((s) => (
            <Grid item xs={6} key={s.name}>
              <label style={{ cursor: "pointer" }}>
                <Box display="flex" alignItems="center">
                  <CustomCheckbox
                    checked={filter.find((e) => e?.id === s?.id)}
                    onChange={(e) => {
                      onChange(e, s);
                    }}
                  />
                  <Typography variant="body2">
                    {i18n._(t`${s.name}`)}
                  </Typography>
                </Box>
              </label>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default FilterCheckboxList;
