import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function SearchIcon({ fill = "black", ...rest }) {
  return (
    <svg
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.2446 0.3624C13.1466 0.2868 13.0188 0.2606 12.8992 0.2918L8.99998 1.5866L5.10078 0.0118L5.08978 0.0102001L5.04478 0.00400009L4.99998 0L4.95498 0.00400009L4.90998 0.0102001L4.89897 0.0118L0.898976 1.334C0.722976 1.3798 0.599976 1.539 0.599976 1.721V11.321C0.599976 11.4448 0.657376 11.5618 0.755376 11.6374C0.826176 11.6922 0.912376 11.721 0.999976 11.721C1.03358 11.721 1.06758 11.7168 1.10078 11.7082L4.99998 10.4126L8.89917 11.9872C8.93237 11.9958 8.96618 12 8.99998 12C9.03358 12 9.06758 11.9958 9.10078 11.9872L13.1008 10.6662C13.277 10.6202 13.4 10.461 13.4 10.279V0.679C13.4 0.555 13.3426 0.4382 13.2446 0.3624ZM1.39998 2.0302L4.59998 0.9164V9.6898L1.39998 10.8034V2.0302ZM5.39998 0.9166L8.59998 2.3092V11.0824L5.39998 9.6898V0.9166ZM12.6 9.9698L9.39997 11.0824V2.3092L12.6 1.1966V9.9698Z"
        fill={fill}
      />
    </svg>
  );
}
