import React, { useEffect, useState } from "react";
import { isDateValid } from "../../utils/dateUtils";
import SpaceListComponent from "../../components/spaces-list/space-list";
import { getSpacesNextListThunk } from "../../redux/actions/space-list-actions";
import {
  getCitiesAreasThunk,
  resetCurrentAsset,
} from "../../redux/actions/misc-actions";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "@reach/router";

import { useQuery } from "../../utils/index";

function SpaceList() {
  const dispatch = useDispatch();
  const region = useSelector((state) => state.region.region);
  const asset = useSelector((state) => state.misc.currentAsset);
  const spaces = useSelector((state) => state.spaceList.spaces);
  const currentPage = useSelector((state) => state.spaceList.currentPage);
  const lastPage = useSelector((state) => state.spaceList.lastPage);
  const perPage = useSelector((state) => state.spaceList.perPage);
  const loadingSpaces = useSelector((state) => state.spaceList.status);
  const filters = useSelector((state) => state.spaceList.filters);

  useEffect(() => {
    // get all misc needed to render menus and stuff
    dispatch(getCitiesAreasThunk());

    return () => {
      dispatch(resetCurrentAsset());
    };
  }, []);

  const handleNextPage = () => {
    dispatch(getSpacesNextListThunk(asset, perPage, currentPage + 1));
  };

  return (
    <SpaceListComponent
      onNextPage={handleNextPage}
      region={region}
      spaces={spaces}
      asset={asset}
      currentPage={currentPage}
      lastPage={lastPage}
      perPage={perPage}
      filters={filters}
      loadingSpaces={loadingSpaces}
    />
  );
}

export default SpaceList;
