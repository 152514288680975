import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function ListIcon({ fill = "black", ...rest }) {
  return (
    <svg
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.9999 0.800049H0.999853C0.779053 0.800049 0.599854 0.979249 0.599854 1.20005V5.00005C0.599854 5.22085 0.779053 5.40005 0.999853 5.40005H12.9999C13.2207 5.40005 13.3999 5.22085 13.3999 5.00005V1.20005C13.3999 0.979249 13.2207 0.800049 12.9999 0.800049ZM12.5999 4.60005H1.39985V1.60005H12.5999V4.60005Z"
        fill={fill}
      />
      <path
        d="M12.9999 6.6001H0.999853C0.779053 6.6001 0.599854 6.7793 0.599854 7.0001V10.8001C0.599854 11.0209 0.779053 11.2001 0.999853 11.2001H12.9999C13.2207 11.2001 13.3999 11.0209 13.3999 10.8001V7.0001C13.3999 6.7793 13.2207 6.6001 12.9999 6.6001ZM12.5999 10.4001H1.39985V7.4001H12.5999V10.4001Z"
        fill={fill}
      />
    </svg>
  );
}
