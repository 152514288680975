import React from "react";

export default function PlusIcon({ fill = "black", ...rest }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0001 17.2007C6.02952 17.2007 2.79932 13.9705 2.79932 9.99999C2.79932 6.02946 6.02952 2.79926 10.0001 2.79926C13.9706 2.79926 17.2008 6.02946 17.2008 9.99999C17.2008 13.9705 13.9706 17.2007 10.0001 17.2007ZM10.0001 3.26592C6.28692 3.26592 3.26598 6.28686 3.26598 9.99999C3.26598 13.7131 6.28692 16.7341 10.0001 16.7341C13.7132 16.7341 16.7341 13.7131 16.7341 9.99999C16.7341 6.28686 13.7132 3.26592 10.0001 3.26592Z"
        fill="#333333"
      />
      <path
        d="M10.2001 4.86664H9.7334V15.1333H10.2001V4.86664Z"
        fill="#333333"
      />
      <path
        d="M15.1334 9.79999H4.8667V10.2667H15.1334V9.79999Z"
        fill="#333333"
      />
    </svg>
  );
}
