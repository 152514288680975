import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import numbro from "numbro";

import CustomSlider from "../custom-slider";

import theme from "../../theme";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { formatCurrencyForTenant } from "../../utils/currencyUtils";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    borderBottom: theme.borders[2],
  },
}));

const ValueFormatted = ({ type, value }) => {
  switch(type) {
  case "currency":
    return <>{formatCurrencyForTenant(value)}</>;
  case "area":
    return <>{value}<span>m<sup>2</sup></span></>;
  default:
    return value;
  }
}

function FilterSliderMobile({
  value = 0,
  minValue = 0,
  maxValue = 100,
  title = "",
  minLabel = "",
  maxLabel = "",
  onCommit = () => {},
  valueType = "", // currency, area
}) {
  const classes = useStyles(theme);

  const [sliderValue, setSliderValue] = useState([0, 100]);

  useEffect(() => {
    setSliderValue(value);
  }, [value]);

  return (
    <Box className={classes.container}>
      <Box display="flex" flexDirection="column">
        <Typography variant="subtitle1">{title}</Typography>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent={"space-between"}
        >
          <Box>
            <Typography variant="body1">{minLabel}</Typography>
            <Typography variant="subtitle1">
              <ValueFormatted value={sliderValue[0]} type={valueType} />
            </Typography>
          </Box>
          <Box ml={2}>
            <Typography variant="body1">{maxLabel}</Typography>
            <Typography variant="subtitle1">
            <ValueFormatted value={sliderValue[1]} type={valueType} />
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box pl={2} pr={2}>
        <CustomSlider
          value={sliderValue}
          min={minValue}
          max={maxValue}
          onChange={(e, newsliderValue) => {
            setSliderValue(newsliderValue);
          }}
          onChangeCommitted={(e, newsliderValue) => {
            onCommit(newsliderValue);
          }}
        />
      </Box>
    </Box>
  );
}

export default FilterSliderMobile;
