import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Filters from "../../components/spaces-list/filters";
import { addSpacesFilterThunk } from "../../redux/actions/space-list-actions";
import { setCurrentAsset } from "../../redux/actions/misc-actions";

function SpaceListFilter() {
  const dispatch = useDispatch();

  // static version
  const asset = useSelector((state) => state.spaceList.asset);
  const currentAsset = useSelector((state) => state.misc.currentAsset);
  const spaceFilters = useSelector((state) => state.spaceList.filters);
  const startDate = useSelector((state) => state.spaceList.filters.start);
  const endDate = useSelector((state) => state.spaceList.filters.end);
  const assets = useSelector((state) => state.misc.assets);

  const onSearch = (id) => {
    const asset = assets.find((a) => parseInt(a.id) === parseInt(id));
    dispatch(addSpacesFilterThunk({ asset }));
    dispatch(setCurrentAsset(asset));
  };

  useEffect(() => {
    const currentAsset = assets.find((a) => parseInt(a.id) === parseInt(asset?.id));
    dispatch(setCurrentAsset(currentAsset));
  }, [assets, asset]);

  return (
    <Filters
      assets={assets}
      asset={currentAsset}
      filters={spaceFilters}
      onSearch={onSearch}
      dateStart={startDate}
      dateEnd={endDate}
    />
  );
}

export default SpaceListFilter;
