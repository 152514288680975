import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CloseIcon from "../../icons/close-icon";
import { getDays, formatDateUtc, dateToDayjs } from "../../utils/dateUtils";
import React, { useState, useEffect } from "react";
import Calendar from "./../calendar";
import theme from "./../../theme";
import SecondaryButton from "./../secondary-button";
import { Trans } from "@lingui/macro";

const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    padding: theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "100%",
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
  },
  title: {
    fontWeight: "bold",
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      width: "80%",
    },
  },
  button: {
    width: "150px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginTop: theme.spacing(1),
    },
  },
  inputContainer: {
    width: "100%",
    height: 32,
    border: theme.borders[1],
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
  calendarIconContainer: {
    borderRight: theme.borders[1],
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 35,
    minWidth: 35,
    height: 32,
  },
  datesContainer: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    width: "100%",
    textTransform: "uppercase",
  },
  calendarIcon: {
    width: 24,
    height: 24,
  },
  rightArrow: {
    transform: "rotate(180deg)",
    width: 21,
    height: 20,
  },
  closeIcon: {
    position: "absolute",
    right: 25,
    cursor: "pointer",
    top: 25,
  },
}));

function FilterDateDialog({ open, onClose = () => {}, startDate, endDate, onSelectDates, calendarProps }) {
  const classes = useStyles(theme);

  const [filterStartDate, setFilterStartDate] = useState(null);
  const [filterEndDate, setFilterEndDate] = useState(null);

  useEffect(() => {
    if (calendarProps.selectRange && startDate && endDate) {
      setFilterStartDate(new Date(startDate).toUTCString());
      setFilterEndDate(new Date(endDate).toUTCString());
    } else if (!calendarProps.selectRange && startDate) {
      setFilterStartDate(new Date(startDate).toUTCString());
    } else {
      setFilterStartDate("");
      setFilterEndDate("");
    }
  }, [startDate, endDate]);

  const handleResetDates = () => {
    setFilterStartDate(null);
    setFilterEndDate(null);
  };

  return (
    <>
      <Dialog
        open={open}
        fullScreen={useMediaQuery(theme.breakpoints.down("xs"))}
        PaperProps={{
          style: {
            display: "flex",
            justifyContent: "center",
            borderRadius: 0,
            borderBox: "box-size",
            borderRadius: "10px",
          },
        }}
      >
        <Box className={classes.card}>
          <Box className={classes.closeIcon} onClick={onClose}>
            <CloseIcon width="15px" height="15px" />
          </Box>
          <Box height={"40px"} />
          <Box mb={2}>
            <Calendar
              startDate={new Date(filterStartDate)}
              endDate={new Date(filterEndDate)}
              onChange={(dates) => {
                setFilterStartDate(new Date(dates[0]).toUTCString());
                setFilterEndDate(new Date(dates[1]).toUTCString());
              }}
              isDouble
              {...calendarProps}
            />
          </Box>
          <Box width="100%" display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
            <Box ml={1}>
              <Typography variant="h6">
                {`${getDays(formatDateUtc(filterStartDate), formatDateUtc(filterEndDate))} `}
                <Trans>DÍAS</Trans>
              </Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <Box onClick={handleResetDates} style={{ cursor: "pointer" }} mr={2}>
                <Typography variant="body1" style={{ textDecoration: "underline" }}>
                  <Trans>Limpiar fechas</Trans>
                </Typography>
              </Box>
              <Button
                variant={"contained"}
                color={"primary"}
                onClick={() => {
                  onSelectDates(dateToDayjs(filterStartDate).utc().toDate(), dateToDayjs(filterEndDate).utc().toDate());
                  onClose();
                }}
              >
                <Trans>Aplicar</Trans>
              </Button>
            </Box>
          </Box>
        </Box>
      </Dialog>
    </>
  );
}

export default FilterDateDialog;
