import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import { Trans } from "@lingui/macro";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

import theme from "../../theme";
import SearchIcon from "../../icons/search-icon";
import PlusIcon from "../../icons/plus-icon";
import MapIcon from "../../icons/map-icon";
import ListIcon from "../../icons/list-icon";
import FiltersDialog from "../../containers/spaces-list/filters-dialog";
import { getThemeById } from "./../../theme";

const useStyles = makeStyles((theme) => ({
  filter: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "90%",
    height: "40px",
    borderTop: theme.borders[2],
    borderBottom: theme.borders[2],
  },
  item: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: "100%",
    width: "100%",
  },
  filterAndListButtons: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: "100%",
  },
  searchBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: "40px",
    width: "100%",
    backgroundColor: theme.palette.white,
    marginTop: theme.spacing(1 / 2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  searchText: {
    width: "100%",
    color: theme.palette.black,
  },
}));

function SpaceListFilterMobile({ showMap, handleShowMap, assets = [], asset, assetName = "", onSearch = () => {} }) {
  const classes = useStyles(getThemeById(asset?.id));

  const [open, setOpen] = useState(false);

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <FiltersDialog
        open={open}
        handleOnClose={() => {
          setOpen(false);
        }}
      />
      <Box className={classes.searchBox}>
        <Box className={classes.item}>
          <Autocomplete
            options={assets}
            getOptionLabel={(option) => option.name}
            className={classes.searchBox}
            blurOnSelect
            onChange={(event, asset) => {
              if (asset) {
                onSearch(asset.id);
              }
            }}
            renderInput={(params) => (
              <>
                <Box mr={1}>
                  <SearchIcon fill={theme.palette.black} />
                </Box>
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    style: {
                      color: theme.palette.black,
                      width: "100%",
                      paddingTop: 0,
                    },
                  }}
                  fullWidth={true}
                  placeholder={assetName}
                />
              </>
            )}
          />
        </Box>
      </Box>
      <Box className={classes.filter}>
        <Box className={classes.filterAndListButtons}>
          <PlusIcon width="20" height="20" />
          <Box
            ml={1}
            onClick={() => {
              setOpen(true);
            }}
          >
            <Typography variant="body2">
              <Trans>Filtros</Trans>
            </Typography>
          </Box>
        </Box>
        <Box
          className={classes.filterAndListButtons}
          ml={2}
          onClick={() => {
            handleShowMap(!showMap);
          }}
        >
          <Box id="show-map-button" display="flex" flexDirection="row">
            {!showMap ? <MapIcon /> : <ListIcon />}
            <Box ml={1}>
              <Typography variant="body2">{!showMap ? <Trans>Mapa</Trans> : <Trans>Lista</Trans>}</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default SpaceListFilterMobile;
