import React from "react";
import { useSelector } from "react-redux";
import CustomSliderComponent from "../../components/custom-slider";

const CustomSliderContainer = (props) => {
  const asset = useSelector((state) => state.misc.currentAsset);

  return <CustomSliderComponent asset={asset} {...props} />;
};

export default CustomSliderContainer;
