import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addSpacesFilterThunk } from "../../redux/actions/space-list-actions";
import { getSpaceFeaturesThunk } from "../../redux/actions/misc-actions";

import FilterMoreDialog from "../../components/spaces-list/filter-more-dialog";

function SpaceListFilterMorePopover({ onClose, open = false, ...rest }) {
  const dispatch = useDispatch();

  const [optionsFilter, setOptionsFilter] = useState({ features: [] });

  const spaceFeatures = useSelector((state) => state.misc.spaceFeatures);
  const spaceFilters = useSelector((state) => state.spaceList.filters);
  const statusSpaceFeaturesServices = useSelector((state) => state.misc.statusSpaceFeaturesServices);
  const asset = useSelector((state) => state.spaceList.asset);

  const handleSubmitFilter = () => {
    dispatch(addSpacesFilterThunk(optionsFilter));
  };

  const handleOptionChange = (type) => (e, option) => {
    if (e.target.checked) {
      setOptionsFilter((state) => ({
        ...state,
        [type]: [...state[type], option],
      }));
    } else {
      setOptionsFilter((state) => ({
        ...state,
        [type]: state[type].filter((f) => f.id !== option.id),
      }));
    }
  };

  useEffect(() => {
    setOptionsFilter({
      features: spaceFilters.features,
    });
  }, [spaceFilters]);

  useEffect(() => {
    if (open) {
      dispatch(getSpaceFeaturesThunk(asset.asset_id));
    }
  }, [open]);

  return (
    <FilterMoreDialog
      features={spaceFeatures}
      featuresFilter={optionsFilter.features}
      onClose={onClose}
      onSubmitFilter={handleSubmitFilter}
      onOptionChange={handleOptionChange}
      clearFilter={() => setOptionsFilter({ features: [] })}
      open={open}
      isloadingRequest={statusSpaceFeaturesServices === "loading"}
      {...rest}
    />
  );
}

export default SpaceListFilterMorePopover;
