import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { addSpacesFilterThunk } from "../../redux/actions/space-list-actions";

import FilterSizePopover from "../../components/spaces-list/filter-size-popover";

function SpaceListFilterSizePopover({ values = [], onClose, ...rest }) {
  const dispatch = useDispatch();

  const [size, setSize] = useState(values);

  const sizeMin = useSelector((state) => state.spaceList.filters.sizeMin);
  const sizeMax = useSelector((state) => state.spaceList.filters.sizeMax);

  const addFilter = () => {
    dispatch(
      addSpacesFilterThunk({ sizeStart: size[0], sizeEnd: size[1] })
    );
  };

  useEffect(() => {
    setSize(values);
  }, [values]);

  return (
    <FilterSizePopover
      values={size}
      onChangeValues={setSize}
      onClose={onClose}
      addFilter={addFilter}
      minMaxValues={[sizeMin, sizeMax]}
      {...rest}
    />
  );
}

export default SpaceListFilterSizePopover;
