import React from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CustomCheckbox from "../custom-checkbox/custom-checkbox-alternative-2";
import { Trans } from "@lingui/macro";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress, Typography } from "@material-ui/core";
import CloseIcon from "../../icons/close-icon";

import theme from "../../theme";
import Popover from "@material-ui/core/Popover";
import { useLingui } from "@lingui/react";
import { t } from "@lingui/macro";

const useStyles = makeStyles((theme) => ({
  typesSpace: {
    maxHeight: "100%",
    minWidth: "260px",
    marginTop: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  boxDivider: {
    borderBottom: theme.borders[2],
  },
  closeIcon: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
}));

function SpaceListFilterTypePopover({
  values = [],
  types = [],
  onChangeValues = () => {},
  addFilter = () => {},
  onClose = () => {},
  isloadingRequest = false,
  ...rest
}) {
  const classes = useStyles(theme);
  const { i18n } = useLingui();

  const handleOnChange = (event, type) => {
    if (event.target.checked) {
      onChangeValues([...values, type]);
    } else {
      onChangeValues([...values.filter((x) => x.id !== type.id)]);
    }
  };

  return (
    <Popover onClose={onClose} {...rest}>
      <Box className={classes.typesSpace}>
        <Box className={classes.closeIcon}>
          <Box onClick={onClose} style={{ cursor: "pointer" }}>
            <CloseIcon width="15px" height="15px" />
          </Box>
        </Box>
        <Box className={classes.boxDivider} pb={2} mb={1}>
          {isloadingRequest ? (
            <CircularProgress size={30} />
          ) : (
            <>
              {types.map((type) => {
                return (
                  <Box display="flex" alignItems="center" key={type.name}>
                    <CustomCheckbox
                      checked={!!values.find((v) => v.id === type.id)}
                      onChange={(e) => {
                        handleOnChange(e, type);
                      }}
                    />
                    <Typography variant="body1">{i18n._(t`${type.name}`)}</Typography>
                  </Box>
                );
              })}
            </>
          )}
        </Box>
        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" mt={2} mb={2}>
          <Typography
            variant="body1"
            onClick={() => {
              onChangeValues([]);
            }}
            style={{ textDecoration: "underline", cursor: "pointer" }}
          >
            <Trans>Borrar todo</Trans>
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              addFilter();
              onClose();
            }}
          >
            <Trans>Aplicar</Trans>
          </Button>
        </Box>
      </Box>
    </Popover>
  );
}

export default SpaceListFilterTypePopover;
