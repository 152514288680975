import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addSpacesFilterThunk } from "../../redux/actions/space-list-actions";

import FilterTypePopover from "../../components/spaces-list/filter-type-popover";
import { getSpaceTypesThunk } from "../../redux/actions/misc-actions";

function SpaceListFilterTypePopover({ values = [], onClose, open, ...rest }) {
  const dispatch = useDispatch();

  const spaceTypes = useSelector((state) => state.misc.spaceTypes);
  const statusSpaceTypes = useSelector((state) => state.misc.statusSpaceTypes);

  const [types, setTypes] = useState(values);

  const addFilter = () => {
    dispatch(addSpacesFilterThunk({ types: [...types] }));
  };

  useEffect(() => {
    setTypes(values);
  }, [values]);

  useEffect(() => {
    if (open) {
      dispatch(getSpaceTypesThunk());
    }
  }, [open]);

  return (
    <FilterTypePopover
      values={types}
      onChangeValues={setTypes}
      onClose={onClose}
      addFilter={addFilter}
      types={spaceTypes}
      open={open}
      statusRequest={statusSpaceTypes}
      isloadingRequest={statusSpaceTypes === "loading"}
      {...rest}
    />
  );
}

export default SpaceListFilterTypePopover;
