import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";

import CloseIcon from "../../icons/close-icon";

import FilterSliderMobile from "./filter-slider-mobile";
import FilterCheckboxList from "./filter-checkbox-list";

import theme from "../../theme";
import { Trans } from "@lingui/macro";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { useLingui } from "@lingui/react";
import { t } from "@lingui/macro";

const useStyles = makeStyles((theme) => ({
  dialog: {
    height: "100%",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    height: "60px",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: theme.borders[2],
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
  spaceType: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    borderBottom: theme.borders[2],
  },
  typeCheckbox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  priceRange: {
    marginTop: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    borderBottom: theme.borders[2],
  },
  buttonBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginTop: theme.spacing(1),
    paddingBottom: theme.spacing(),
    borderBottom: theme.borders[2],
  },
  button: {
    width: "90%",
  },
  services: {
    display: "flex",
    flexDirection: "column",
    marginTop: theme.spacing(2),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    paddingBottom: theme.spacing(2),
    borderBottom: theme.borders[2],
  },
}));

function SpaceListFilterDialog({
  spaceTypes = [],
  spaceFeatures = [],
  typesFilter = [],
  usesFilter = [],
  featuresFilter = [],
  price = [0, 100],
  minMaxPrice = [0, 100],
  showPrices,
  size = [0, 100],
  minMaxSize = [0, 100],
  open,
  onClose,
  submit,
}) {
  const classes = useStyles(theme);
  const { i18n } = useLingui();

  const [filterTypes, setFilterTypes] = useState([]);
  const [filterUses, setFilterUses] = useState([]);
  const [filterFeatures, setFilterFeatures] = useState(featuresFilter);
  const [filterPrice, setFilterPrice] = useState(price);
  const [filterSize, setFilterSize] = useState(size);

  const handleTypesChanges = (e, option) => {
    if (e.target.checked) {
      setFilterTypes([...filterTypes, option]);
    } else {
      setFilterTypes(filterTypes.filter((f) => f.id !== option.id));
    }
  };

  const handleFeaturesChanges = (e, option) => {
    if (e.target.checked) {
      setFilterFeatures([...filterFeatures, option]);
    } else {
      setFilterFeatures(filterFeatures.filter((f) => f.id !== option.id));
    }
  };

  const handleUsesChanges = (e, option) => {
    if (e.target.checked) {
      setFilterUses([...filterUses, option]);
    } else {
      setFilterUses(filterUses.filter((u) => u.id !== option.id));
    }
  };

  const handleSubmit = () => {
    const data = {
      uses: filterUses,
      features: filterFeatures,
      types: filterTypes,
      priceStart: filterPrice[0],
      priceEnd: filterPrice[1],
      sizeStart: filterSize[0],
      sizeEnd: filterSize[1],
    };
    submit(data);
    onClose();
  };

  useEffect(() => {
    setFilterTypes(typesFilter);
    setFilterUses(usesFilter);
    setFilterFeatures(featuresFilter);
    setFilterPrice(price);
    setFilterSize(size);
  }, [usesFilter, featuresFilter, price, size]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose();
      }}
      fullScreen
      PaperProps={{
        style: {
          display: "flex",
          justifyContent: "center",
          borderRadius: 0,
          borderBox: "box-size",
        },
      }}
    >
      <Box height="100%">
        <Box className={classes.header}>
          <Typography
            variant="subtitle1"
            style={{ textDecoration: "underline" }}
          >
            <Box
              onClick={() => {
                setFilterPrice([minMaxPrice[0], minMaxPrice[1]]);
                setFilterSize([minMaxSize[0], minMaxSize[1]]);
                setFilterUses([]);
                setFilterFeatures([]);
              }}
            >
              <Trans>Limpiar todo</Trans>
            </Box>
          </Typography>
          <Box
            width="25px"
            height="25px"
            onClick={() => {
              onClose();
            }}
          >
            <CloseIcon />
          </Box>
        </Box>
        {showPrices && (
          <FilterSliderMobile
            value={filterPrice}
            minValue={minMaxPrice[0]}
            maxValue={minMaxPrice[1]}
            onCommit={setFilterPrice}
            title={<Trans>Rango de Precios</Trans>}
            minLabel={<Trans>Precio minimo</Trans>}
            maxLabel={<Trans>Precio máximo</Trans>}
            valueType={"currency"}
          />
        )}
        <FilterSliderMobile
          value={filterSize}
          minValue={minMaxSize[0]}
          maxValue={minMaxSize[1]}
          onCommit={setFilterSize}
          title={<Trans>Tamaño del espacio</Trans>}
          minLabel={<Trans>Tamaño mínimo</Trans>}
          maxLabel={<Trans>Tamaño Máximo</Trans>}
          valueType={"area"}
        />
        <FilterCheckboxList
          title={i18n._(t`Ubicación`)}
          list={spaceTypes}
          filter={typesFilter}
          onChange={handleTypesChanges}
        />
        <FilterCheckboxList
          title={i18n._(t`Caracteristicas`)}
          list={spaceFeatures}
          filter={filterFeatures}
          onChange={handleFeaturesChanges}
        />
        <Box className={classes.buttonBox}>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={handleSubmit}
          >
            <Trans>Aplicar</Trans>
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}

export default SpaceListFilterDialog;
