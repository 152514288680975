import React from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import theme from "./../../theme";

import CloseIcon from "../../icons/close-icon";
import CustomCheckbox from "../custom-checkbox/custom-checkbox-alternative-2";

import { useLingui } from "@lingui/react";
import { t } from "@lingui/macro";
import { Trans } from "@lingui/macro";
import { CircularProgress } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
  },
  header: {
    display: "flex",
    justifyContent: "center",
    height: "10%",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    borderBottom: theme.borders[2],
    position: "relative",
  },
  closeIcon: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  main: {
    height: "80%",
    overflowY: "auto",
  },
  services: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(2),
  },
  characteristics: {
    display: "flex",
    flexDirection: "column",
    marginTop: theme.spacing(2),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    paddingBottom: theme.spacing(2),
  },
  footer: {
    display: "flex",
    height: "10%",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  featureItem: {
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
}));

function SpaceListMoreFilter({
  open,
  onClose,
  features = [],
  featuresFilter = [],
  onSubmitFilter = () => {},
  onOptionChange = () => {},
  clearFilter = () => {},
  isloadingRequest = false,
}) {
  const classes = useStyles(theme);

  const { i18n } = useLingui();

  const Header = () => (
    <>
      <Box className={classes.closeIcon}>
        <Box onClick={onClose} style={{ cursor: "pointer" }}>
          <CloseIcon width={15} height={15} />
        </Box>
      </Box>
    </>
  );

  const FeatureList = ({ title = "", list = [], filter = [], onChange = () => {} }) => (
    <Box className={classes.services}>
      <Typography variant="h1">{title}</Typography>
      <Box mt={3} width="100%">
        <Grid container spacing={1}>
          {isloadingRequest ? (
            <CircularProgress size={30} />
          ) : (
            <>
              {list.map((s) => (
                <Grid item xs={4} key={s.name}>
                  <Box height={"30px"} style={{ cursor: "pointer" }}>
                    <Box display="flex" alignItems="center">
                      <CustomCheckbox
                        checked={!!filter.find((f) => f.id === s.id)}
                        onChange={(e) => {
                          onChange(e, s);
                        }}
                      />
                      <Typography variant="body2" className={classes.featureItem}>
                        {i18n._(t`${s.name}`)}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              ))}
            </>
          )}
        </Grid>
      </Box>
    </Box>
  );

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose();
      }}
      PaperProps={{
        style: {
          height: "623px",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          borderRadius: 10,
          borderBox: "box-size",
        },
      }}
    >
      <Box className={classes.card}>
        <Header />
        <Box className={classes.main}>
          <FeatureList
            title={i18n._(t`Características`)}
            list={features}
            filter={featuresFilter}
            onChange={onOptionChange("features")}
          />
        </Box>
        <Box className={classes.footer}>
          <Typography variant="body1" style={{ textDecoration: "underline", cursor: "pointer" }} onClick={clearFilter}>
            <Trans>Borrar todo</Trans>
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              onSubmitFilter();
              onClose();
            }}
          >
            <Trans>Aplicar</Trans>
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}

export default SpaceListMoreFilter;
